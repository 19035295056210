<template>
  <div class="page-classroom-edit">
    <div class="my-page">
      <van-form @submit="save" style="background-color: transparent;">
        <div class="my-card">
          <van-field
            required
            v-model="viewData.Title"
            name="Title"
            label="班级圈标题"
            maxlength="20"
            placeholder="班级圈标题"
            :rules="[{ required: true, message: '请填写班级圈标题' }]"
          />
          <van-field
            required
            v-model="viewData.Content"
            rows="4"
            label="班级圈内容"
            type="textarea"
            placeholder="请输入班级圈内容"
            :rules="[{ required: true, message: '请输入班级圈内容' }]"
          />
          <van-field
            readonly
            required
            v-model="viewData.PeopleIdList"
            name="PeopleIdList"
            label="接收对象"
            placeholder="接收对象"
            :rules="[{ required: true, message: '请选择接收对象' }]"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-checkbox-group
              v-model="peopleIds"
              @change="selectFn"
              style="padding: 10px 20px 0 20px"
            >
              <van-checkbox
                v-for="(item, index) in peopleList"
                :key="index"
                :name="item"
                style="margin-bottom: 10px; font-size: 16px;"
                >{{ item.ClassName }}</van-checkbox
              >
            </van-checkbox-group>
          </van-popup>
        </div>
        <div class="my-card">
          <van-field name="accessoryList" label="班级圈照片">
            <template #input>
              <van-uploader
                v-model="viewData.accessoryList"
                :deletable="true"
                :preview-full-image="false"
                accept="image/*"
                :before-read="beforeRead"
                @click-preview="review"
              />
            </template>
          </van-field>
        </div>
        <van-button
          :loading="loading"
          block
          color="#ffe500"
          type="info"
          native-type="submit"
          >完成</van-button
        >
      </van-form>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Checkbox,
  CheckboxGroup,
  Toast
} from 'vant'
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Toast.name]: Toast
  },
  data () {
    return {
      id: null,
      viewData: {
        accessoryList: []
      },
      acceptUserList: [],
      peopleList: [],
      peopleIds: [],
      showPicker: false,
      loading: false
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetail()
    }
    // 获取接收对象列表
    this.getClassList()
  },
  methods: {
    getClassList () {
      this.$axios.get('/api/Class/GetClassByApp').then(res => {
        if (res.code === 200) {
          this.peopleList = res.data
        } else {
          this.$toast.fail(res.msg || '操作失败')
        }
      })
    },
    getDetail () {
      this.$axios
        .get('/api/Repair/Get', {
          ID: this.id
        })
        .then(res => {
          this.viewData = res.data
        })
    },
    onConfirm (value) {
      this.viewData.AcceptUserName = value.realName
      this.viewData.AcceptUserID = value.UserID
      this.showPicker = false
    },
    beforeRead (file) {
      Toast.loading({
        message: '加载中...',
        duration: 0
      })
      let formData = new FormData()
      formData.append('LastFile', file)
      formData.append('FileName', file.name)
      this.$axios.upload('/api/File/UploadFile', formData).then(res => {
        Toast.clear()
        if (res.code === 200) {
          this.viewData.accessoryList.push({
            name: file.name,
            osskey: res.data.osskey,
            uploadUrl: res.data.uploadUrl,
            url: res.data.url
          })
          return true
        } else {
          this.$toast.fail(res.msg || '操作失败')
          return false
        }
      })
    },
    review (file) {
      if (file.url) {
        window.open(file.url)
      }
    },
    selectFn (v) {
      let arr = []
      v.map(item => {
        arr.push(item.ClassName)
      })
      this.viewData.PeopleIdList = arr.join()
    },
    save () {
      let that = this
      let arr = []
      this.peopleIds.map(item => {
        arr.push({
          ClassID: item.ID
        })
      })
      delete this.viewData.PeopleIdList
      this.$axios
        .post('/api/ClassCircle/Add', {
          ...this.viewData,
          AccessoryPostList: this.viewData.accessoryList,
          ClassCircle_JurisdictionDtoList: arr
        })
        .then(res => {
          if (res.code === 200) {
            this.$toast.success(res.msg || '操作成功')
            setTimeout(() => {
              that.$router.replace({
                path: 'FamilyHome',
                query: {
                  active: 0
                }
              })
            }, 1000)
          } else {
            this.$toast.fail(res.msg || '操作失败')
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
